import { Box } from '@packages/shared';
import { useConfig } from '@packages/utilities';
import type { ParagraphMeta } from '../../../interfaces';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { VideoConsentOverlay } from '../VideoConsentOverlay';

export type VideoType = ParagraphMeta & {
  type: 'video_youtube';
  field_margin?: string;
  field_video_autoplay: boolean;
  field_video_id: string;
};

export const Video = ({ data }: { data: VideoType }) => {
  const config = useConfig();
  const {
    type,
    field_margin: margin,
    field_video_id: videoId,
    field_video_autoplay: autoplay,
  } = data;

  const { url, name, provider } = config.video[type];

  const iframeUrl = url.replace('<id>', videoId).replace('<autoplay>', autoplay ? '1' : '0');

  return (
    <Box
      sx={{
        margin,
        position: 'relative',

        // aspect ratio 16:9
        paddingBottom: '56.25%',

        '& iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <VideoConsentOverlay
        sessionStorageKey={`hasSeenOverlay${type}`}
        texts={{ provider, providerName: name }}
      >
        <iframe title={videoId} src={iframeUrl} allowFullScreen />
      </VideoConsentOverlay>
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.Video]: Video });
