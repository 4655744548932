import { useCookieConsent } from '@packages/utilities';
import { useEffect } from 'react';
import { useTracking } from '@packages/tracking';

export const Rooms3D = ({ room }: { room: string }) => {
  const isAllowed: boolean = useCookieConsent('C0004');
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'RenderRooms3d',
        rooms3dRoomName: room,
      });
    }
  }, [dispatchGTMEvent, isAllowed, room]);

  // use a state here to allow clientside changes of consent take effect on toggle the right container
  return <div id={`rooms-3d-${room}`} />;
};
