/* istanbul ignore file */
import { useIntersectionObserver } from '@packages/shared';
import { useRef } from 'react';

export const useRecoElement = () => {
  const recoEl = useRef<HTMLDivElement | null>(null);

  const { isIntersecting: isInView } = useIntersectionObserver(recoEl, {
    threshold: 1,
  });
  const { isIntersecting: isInThreshold } = useIntersectionObserver(recoEl, {
    threshold: 0,
    rootMargin: '500px 0px 500px 0px',
    root: recoEl.current,
  });

  return { recoEl, isInView, isInThreshold: isInView || isInThreshold };
};
