import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { ProductRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductRecommendationFragmentFragmentDoc';
import type { GlycerinTile } from '@packages/tracking';
import { GlycerinTileType } from '@packages/tracking';

/**
 * This function is used to retrieve the Glycerin tiles from the recommendations.
 * @param recommendations - The recommendations to filter and map to Glycerin tiles.
 * @param rdeTypeAbbr - The RDE type abbreviation. Is used to find out if tileType is Ad or Product.
 * @returns GlycerinTile[] - An array of GlycerinTile objects.
 */
export const getProductGlycerinTiles = (
  recommendationsData: FragmentType<typeof ProductRecommendationFragmentFragmentDoc>[],
  rdeTypeAbbr: string,
): GlycerinTile[] => {
  const recommendations = getFragmentData(
    ProductRecommendationFragmentFragmentDoc,
    recommendationsData,
  );

  return recommendations.map((recommendation, index) => {
    const tileType: GlycerinTileType = rdeTypeAbbr.startsWith('criteo_')
      ? GlycerinTileType.AD
      : GlycerinTileType.PRODUCT;

    const adId = tileType === GlycerinTileType.AD ? 'criteo' : undefined;
    const articleId =
      tileType === GlycerinTileType.PRODUCT
        ? `${recommendation.product!.orderNumber}${recommendation.product!.promotion}`
        : undefined;
    const productId =
      tileType === GlycerinTileType.PRODUCT ? recommendation.product!.akl : undefined;

    return {
      productId,
      articleId,
      adId,
      tileType,
      listPosition: index + 1,
    };
  });
};
