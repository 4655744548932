import { useCookieConsent } from '@packages/utilities';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useTracking } from '@packages/tracking';

/**
 * This component represents external JavaScript resources to be used on content pages.
 * This component delivers branded content generated by Loadbee.
 *
 * @param externalApiKey String contains Api Key
 * @param brand String contains the brand to identify Content
 * @param urlProducts String contains the url to link to
 * @returns JSX.Element to be placed in PageLayout
 */
export const Loadbee = ({
  externalApiKey,
  brand,
  urlProducts,
}: {
  externalApiKey: string;
  brand: string;
  urlProducts: string;
}) => {
  const isAllowed: boolean = useCookieConsent('C0004');

  const { locale } = useIntl();

  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (isAllowed) {
      dispatchGTMEvent({
        event: 'RenderLoadbee',
      });
    }
  }, [dispatchGTMEvent, isAllowed]);

  return (
    <div
      id={`loadbee-${brand}`}
      className="loadbeeTabContent"
      data-loadbee-locale={locale.replace('-', '_')}
      data-loadbee-apikey={externalApiKey}
      data-loadbee-catalogue={brand}
      data-loadbee-template={`${brand}_catalogue`}
      data-loadbee-cat-products={urlProducts}
      data-loadbee-debug="false"
    />
  );
};
