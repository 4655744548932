/* istanbul ignore file */
import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { CriteoPlacementBeaconsFragmentFragmentDoc } from '@packages/gql/generated/shopping/CriteoPlacementBeaconsFragmentFragmentDoc';
import { ProductRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductRecommendationFragmentFragmentDoc';
import type {
  GTMEventGlycerinDisplayRecommendation,
  GenericTrackingEvent,
} from '@packages/tracking';
import { useTracking } from '@packages/tracking';
import { useEffect } from 'react';
import { getProductGlycerinTiles } from '../../helpers/getProductGlycerinTiles';
import type { PageTemplate, RdeType, RecoSliderProps } from '../../types';

/* GraphQL */ `
  fragment CriteoPlacementBeaconsFragment on CriteoProductsResponse {
    placementBeacons {
      url
      purpose
    }
  }
`;

type GtmEvent = {
  pageTemplate: PageTemplate;
  akls: Array<string>;
  rdeTypeAbbr: string;
  outputElementNo: number;
};

type TrackingProps = {
  elementIndex?: number;
  pageTemplate: PageTemplate;
  placementBeaconsData?: FragmentType<typeof CriteoPlacementBeaconsFragmentFragmentDoc>;
  rdeType: RdeType;
  rdeTypeAbbr?: string;
  recommendations?: FragmentType<typeof ProductRecommendationFragmentFragmentDoc>[];
  title: string;
  trackingCondition: boolean;
} & Pick<RecoSliderProps, 'onSeen'>;

export const useProductsRecoTracking = ({
  elementIndex,
  pageTemplate,
  placementBeaconsData,
  rdeType,
  rdeTypeAbbr,
  recommendations,
  title,
  trackingCondition,
  onSeen,
}: TrackingProps) => {
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    if (
      trackingCondition &&
      elementIndex !== undefined &&
      recommendations !== undefined &&
      rdeTypeAbbr
    ) {
      // seen tracking
      onSeen?.();

      // seen tracking
      dispatchGTMEvent<GenericTrackingEvent<GtmEvent>>({
        event: 'ga_event',
        eventCategory: 'elementImpression',
        eventLabel: 'productRecoImpression',
        eventValue: {
          rdeTypeAbbr,
          outputElementNo: (elementIndex ?? 0) + 1,
          pageTemplate,
          akls: recommendations.map(
            (r) => getFragmentData(ProductRecommendationFragmentFragmentDoc, r).product!.akl,
          ),
        },
      });

      // glycerin seen tracking
      dispatchGTMEvent<GTMEventGlycerinDisplayRecommendation>({
        event: 'DisplayRecommendation',
        DisplayRecommendationData: {
          name: title === '' ? rdeType : title,
          // TODO: add placement as prop in trackingProps -> https://empiriecom.atlassian.net/browse/INFORM-2616
          // placement: GlycerinPlacement.CONTENT,
          recoId: rdeTypeAbbr,
          recoType: 'carousel',
          strategy: rdeType,
          tiles: getProductGlycerinTiles(recommendations, rdeTypeAbbr),
          totalSize: recommendations.length,
        },
      });

      // execute seen for criteo products (see JPWP-12403)
      if (rdeTypeAbbr.startsWith('criteo_')) {
        recommendations.forEach((recommendation) => {
          const r = getFragmentData(ProductRecommendationFragmentFragmentDoc, recommendation);
          const { trackingUrls } = r.tracking ?? {};

          if (trackingUrls && trackingUrls.length) {
            const { OnLoadBeacon, OnViewBeacon } = trackingUrls.reduce(
              (acc, item) => ({ ...acc, [item.purpose]: item.url }),
              {} as any,
            );

            // TODO: catch errors should be logged somewhere
            if (OnLoadBeacon) {
              fetch(OnLoadBeacon)
                .then((response) => {
                  if (response.status >= 200 && response.status <= 299 && OnViewBeacon) {
                    fetch(OnViewBeacon).catch(() => {});
                  }
                })
                .catch(() => {});
            }
          }
        });
        if (placementBeaconsData) {
          const { placementBeacons } = getFragmentData(
            CriteoPlacementBeaconsFragmentFragmentDoc,
            placementBeaconsData,
          );

          if (placementBeacons && placementBeacons.length) {
            const { OnLoadBeacon, OnViewBeacon } = placementBeacons.reduce(
              (acc, item) => ({ ...acc, [item.purpose]: item.url }),
              {} as any,
            );

            if (OnLoadBeacon) {
              fetch(OnLoadBeacon)
                .then((response) => {
                  if (response.status >= 200 && response.status <= 299 && OnViewBeacon) {
                    fetch(OnViewBeacon).catch(() => {});
                  }
                })
                .catch(() => {});
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchGTMEvent,
    elementIndex,
    pageTemplate,
    placementBeaconsData,
    rdeType,
    rdeTypeAbbr,
    recommendations,
    title,
    trackingCondition,
    onSeen,
  ]);
};
