import {
  Box,
  ConditionalWrapper,
  Link,
  Typography,
  useTrackCampaign,
  Tooltip,
} from '@packages/shared';
import { useRef } from 'react';
import { useConfig } from '@packages/utilities/src/useConfig/useConfig';
import { fontSwitcher } from '../../utils';
import { useGetTooltipContent } from '../../hooks/useGetTooltipContent';
import { buildLinkWithArguments } from '../../utils/buildLinkWithArguments';
import type { HeadlineLevel, HeadlineProps } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';
import { SoftHyphenInjector } from '../SoftHyphenInjector';

const marginVariants = {
  big: '1.5rem 0',
  medium: '0.75rem 0',
  small: '0.25rem 0',
  none: '0',
};

export const CmsHeadline = ({ data, sx }: HeadlineProps) => {
  const {
    field_margin_variant: marginVariant = 'none',
    field_link: fieldLink,
    field_link_title: linkTitle,
    field_link_target: linkTarget = '_self',
    field_text: headlineText,
    field_text_subheadline: subHeadlineText,
    field_no_h_element: noHeadlineTag,
    field_variation: styleVariation,
    field_text_align: textAlign,
    field_level: mainHeadlineLevel = 1,
    field_level_style: styleLevel,
    field_font: headlineFont,
    field_font_color: fontColor,
    field_tracking: tracking,
    promotion_item: tooltipDataPromotion,
    content_snippet_bundled: tooltipDataContentSnippet,
    behavior_paragraph_tooltip_activation: tooltipActivation,
    behavior_paragraph_tooltip_positioning: tooltipPositioning,
  } = data;

  const tooltipContent = useGetTooltipContent(tooltipDataPromotion, tooltipDataContentSnippet);
  const {
    staticContent: { cmsFontToTheme },
  } = useConfig();

  // when styleVariation is set to 2, add a spacer
  const addSpacer: Boolean = styleVariation === 2;

  // What the main headline looks like
  const mainHeadlineStyleLevel = styleLevel || mainHeadlineLevel;

  // Level of the sub headline. Should be 2 levels below main level (but minumum 6 of course)
  const subHeadlineLevel = Math.min(mainHeadlineLevel + 2, 6) as HeadlineLevel;

  // What the sub headline looks like. Should be 2 below main level (but minumum 6 of course)
  const subHeadlineStyleLevel = styleLevel
    ? (Math.min(mainHeadlineStyleLevel + 2, 6) as HeadlineLevel)
    : subHeadlineLevel;

  const mainHeadline = (
    <Typography
      variant={`h${mainHeadlineStyleLevel}`}
      component={noHeadlineTag ? 'div' : `h${mainHeadlineLevel}`}
      className={fontSwitcher(headlineFont)}
      {...(noHeadlineTag && { 'aria-level': mainHeadlineLevel, role: 'heading' })}
      sx={{
        hyphens: 'auto', // Method of controlling when words at the end of lines should be hyphenated using the "hyphens" property.
        color: fontColor,
        textAlign,
        ...(addSpacer
          ? {
              '&::after': {
                content: '""',
                width: '3.875rem',
                borderBottom: '0.125rem solid',
                borderColor: 'primary.main',
                display: 'block',
                marginTop: 1,
                marginBottom: 1,
                marginLeft: textAlign === 'center' || textAlign === 'right' ? 'auto' : '0',
                marginRight: textAlign === 'center' || textAlign === 'left' ? 'auto' : '0',
              },
            }
          : {}),
      }}
    >
      {tooltipContent && !!tooltipActivation ? (
        <Tooltip variant="inline" content={tooltipContent} positioning={tooltipPositioning}>
          <SoftHyphenInjector text={headlineText} />
        </Tooltip>
      ) : (
        <SoftHyphenInjector text={headlineText} />
      )}
    </Typography>
  );

  const subHeadline = (
    <Typography
      variant={`h${subHeadlineStyleLevel}`}
      component={noHeadlineTag ? 'div' : `h${subHeadlineLevel}`}
      className={fontSwitcher(headlineFont)}
      {...(noHeadlineTag && { 'aria-level': subHeadlineLevel, role: 'heading' })}
      sx={{
        color: fontColor,
        textAlign,
      }}
    >
      {subHeadlineText && <SoftHyphenInjector text={subHeadlineText} />}
    </Typography>
  );

  const headlineRef = useRef<HTMLDivElement>(null);
  const tmpLink = buildLinkWithArguments(fieldLink, data);
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);
  const { trackClick } = useTrackCampaign(data, headlineRef, finalLink);
  return (
    <Box
      component="div"
      ref={headlineRef}
      sx={{
        m: marginVariants[marginVariant],
        '&': cmsFontToTheme,
        ...sx,
      }}
      data-tracking={tracking}
    >
      <ConditionalWrapper
        condition={!!fieldLink}
        wrapper={(ch) => (
          <Link
            href={finalLink}
            {...(linkTarget && { target: linkTarget })}
            {...(linkTarget === '_blank' && { rel: 'noopener' })}
            title={linkTitle || ''}
            color="inherit" // no highlight color
            underline="none"
            onClick={() => trackClick()}
          >
            {ch}
          </Link>
        )}
      >
        <>
          {mainHeadline}
          {subHeadlineText && subHeadline}
        </>
      </ConditionalWrapper>
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.Headline]: CmsHeadline });
