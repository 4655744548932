import type { ButtonBaseProps } from '@mui/material';
import { ButtonBase, Typography } from '@mui/material';
import {
  useTracking,
  type GTMEventGlycerinButtonClick,
  type GTMEventGlycerinFormSubmitClick,
  type GlycerinButtonClickPayload,
  type GlycerinFormSubmitClickPayload,
} from '@packages/tracking';
import type { ComponentProps } from 'react';

export interface LinkButtonProps extends ButtonBaseProps {
  variant?: ComponentProps<typeof Typography>['variant'];
  /**
   * If the link text should be underlined
   *
   * @default false
   */
  disableUnderlined?: boolean;
  clickTrackingProps?: GlycerinButtonClickPayload | GlycerinFormSubmitClickPayload;
}

/**
 * render a link that has no href-target but onClick doings.
 * Ripple is disabled and link like typography is added.
 * @param props
 * @returns JSX.Element
 */
export const LinkButton: React.FC<LinkButtonProps> = ({
  variant = 'body2',
  disableUnderlined = false,
  clickTrackingProps,
  onClick,
  ...props
}) => {
  const dispatchGTMEvent = useTracking();
  const { type } = props;
  const { category, target, detail, label, placement, variantIds, custom } =
    clickTrackingProps ?? {};

  return (
    <ButtonBase
      onClick={(event) => {
        if (type === 'submit') {
          dispatchGTMEvent<GTMEventGlycerinFormSubmitClick>({
            event: 'FormSubmitClick',
            FormSubmitClickData: {
              category: category ?? 'form-submit-click',
              target,
              detail,
              label,
              placement,
              formId:
                clickTrackingProps && 'formId' in clickTrackingProps
                  ? clickTrackingProps.formId
                  : undefined,
              variantIds,
              custom,
            },
          });
        } else {
          dispatchGTMEvent<GTMEventGlycerinButtonClick>({
            event: 'ButtonClick',
            ButtonClickData: {
              category: category ?? 'button-click',
              target,
              detail,
              label,
              placement,
              variantIds,
              custom,
            },
          });
        }
        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
      disableRipple
    >
      <Typography
        variant={variant}
        sx={{ textDecoration: disableUnderlined ? 'none' : 'underline' }}
        component="span"
      >
        {{ ...props }.children}
      </Typography>
    </ButtonBase>
  );
};
