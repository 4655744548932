import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { Card, Image, Link } from '@packages/shared';
import { useTracking } from '@packages/tracking';
import { BrandRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/BrandRecommendationFragmentFragmentDoc';
import { getClickTrackingBrandsCategoriesURLExtension } from '../../helpers/getClickTrackingBrandsCategoriesURLExtension';
import type { PageTemplate, RdeType } from '../../types';

/* GraphQL */ `
  fragment BrandRecommendationFragment on BrandRecommendation {
    brand {
      image
      name
      url
      categoryId
      parentName
      includedInCategories
    }
    tracking {
      token
      logic
    }
  }
`;

export type RecoBrandCardProps = {
  /**
   * number of brands
   */
  brandsLength: number;
  /**
   * pageTemplate for products tracking
   */
  pageTemplate: PageTemplate;
  /**
   * position of brand
   */
  position: number;
  recommendationData: FragmentType<typeof BrandRecommendationFragmentFragmentDoc>;
  /**
   * Recommendation type
   */
  rdeType: RdeType;
};

export const RecoBrandCard = ({
  brandsLength,
  recommendationData,
  pageTemplate,
  position,
  rdeType,
}: RecoBrandCardProps) => {
  const dispatchGTMEvent = useTracking();
  const { brand, tracking } = getFragmentData(
    BrandRecommendationFragmentFragmentDoc,
    recommendationData,
  );

  return (
    <Card sx={{ position: 'relative', height: '130px' }}>
      <Link
        href={`${brand.url}${
          getClickTrackingBrandsCategoriesURLExtension({
            cid: brand.categoryId || '',
            itemsToLoad: brandsLength,
            pageTemplate,
            rdeType,
            recoLogic: tracking.logic,
            name: brand.name,
            position: position + 1,
          }) || ''
        }`}
        onClick={() => {
          dispatchGTMEvent({
            event: 'ga_event',
            eventAction: 'click',
            eventCategory: 'recoClick',
            eventLabel: 'brandRecoItemClick',
            eventValue: {
              trackingToken: tracking.token,
              // this is sent as pid, because prudsys uses the pid field for the cid aswell, see INFORM-2133
              pid: brand.categoryId,
            },
          });
        }}
        sx={{
          display: 'block',
          position: 'relative',
          width: '100%',
          height: '100%',
          '& > img': { filter: 'grayscale(100%)' },
          '& > img:hover': { filter: 'grayscale(0%)' },
        }}
      >
        <Image
          src={`${brand.image}?$brand_pace$`}
          alt={`Brand ${brand.name} Logo`}
          fill
          style={{ objectFit: 'contain' }}
        />
      </Link>
    </Card>
  );
};
